<template>
  <div
    class="modal fade"
    id="ModalDoCreate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalDoCreateLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalDoCreateLabel"
          >Thêm combo</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Tên gói </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="slt_pack" @change="selectPack()">
                 <option class="robo-16-500" :value="null">Chọn gói</option>
                 <option class="robo-16-500" v-for="pack in packages.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1"
            >Giá mỗi tháng </label>
            <input
              type="number"
              class="form-control border"
              readonly
              v-model="pack_price" disabled
            >
          </div>
          <div class="mb-3 row">
            <div class="col-6">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Số tháng </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.package_months"
            >
          </div>
          <div class="col-6">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Số tháng khuyến mãi </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.combo_months"
            >
          </div>
          </div>
          <div class="mb-3 row">
             <div class="col-6">
                <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Giá gốc làm tròn </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.package_price"
            >
            </div>
            <div class="col-6">
                <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Giá sau khuyến mãi </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.combo_price"
            >
            </div>
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Mô tả </label>
            <textarea class="form-control" v-model="form.description" rows="3">

            </textarea>
          </div>
          <div class="mb-3 d-flex">
            <input
              class="checkbox-size"
              type="checkbox"
              id="checkbox"
              v-model="form.status"
            >
            <label
              for="checkbox"
              class="robo-16-400 ml-2"
            >
              Active
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Tạo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalDoCreate',
  data () {
    return {
      loading: false,
      packages: [],
      pack_price: 0,
      slt_pack: null,
      form: {
        package_months: 0,
        package_price: 0,
        combo_months: 0,
        combo_price: 0,
        status: 0,
        description: '',
        package_id: 0
      }
    }
  },
  mounted () {
    this.getPackages()
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.form.package_months) {
        alert('Vui lòng nhập số tháng')
        return
      }
      if (self.form.status) {
        self.form.status = 1
      } else {
        self.form.status = 0
      }
      self.$emit('onCreate', self.form)
      self.form = {
        package_months: 0,
        package_price: 0,
        combo_months: 0,
        combo_price: 0,
        status: 0,
        description: ''
      }
    },
    async getPackages () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: 50,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getPackages(params).then(res => {
          self.packages = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    selectPack () {
      this.pack_price = this.slt_pack?.price_per_month
      this.form.package_id = this.slt_pack?.id
    }
  }
}
    </script>

<style scoped>
.checkbox-size {
  width: 18px;
  height: 18px;
  accent-color: #03267a;
}
</style>