<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog" id="modal--tags">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Tags</h5>
						<span class="btn--close"></span>
					</div>
					<div class="modal-body">
						<div class="row mt-2">
							<div class="col-sm-12">
								<div style="padding: 15px 15px;">
									<div class="row">
										<div class="form-group">
											<label class="robo-16-500">Tên tags</label>
											<input type="text" class="form-control mt-2" placeholder="" v-model="form.name">
											<small v-if="!form.name && is_submit" class="text-warning">Không để trống</small>
										</div>
										<div class="form-group mt-3">
											<label class="robo-16-500">Type</label>
											<select class="form-control" id="exampleFormControlSelect1" v-model="form.type">
												<option value="1">CURRENT</option>
												<option value="2">PREVIOUS</option>
											</select>
											<span v-if="!form.code && is_submit" class="text-warning">Không để trống</span>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</div>
					<div class="modal-footer">
            			<button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
						<button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit()">Update</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'ModelTags',
  props: ['edit'],
  data () {
    return {
      form: {
        name: '',
        type: 1
      },
      is_submit: false
    }
  },
  watch: {
    edit: {
      handler: function () {
        let self = this
        if (self.edit) {
          self.form.name = self.edit && self.edit.name ? self.edit.name : ''
          self.form.type = self.edit && self.edit.type ? self.edit.type : 1
          self.is_submit = false
        } else {
          self.form.name = ''
          self.form.type = 1
          self.is_submit = false
        }
      },
      deep: true
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal--symptom-category').on('hide.bs.modal', function (e) {
        if (!self.edit) {
          self.form.name = ''
          self.form.type = ''
        }
      })
    })
  },
  methods: {
    async onSubmit () {
      let self = this
      try {
        self.is_submit = true
        if (self.form.name && self.form.type) {
          if (self.edit) {
            self.form.id = self.edit && self.edit.id
            self.$emit('onSubmitUpdate', self.form)
            self.is_submit = false
          } else {
            self.$emit('onSubmit', self.form)
            self.is_submit = false
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style scoped></style>