<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog" id="modal--person-diseases-notes">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Ghi chú</h5>
						<span class="btn--close"></span>
					</div>
					<div class="modal-body">
						<div class="row mt-2">
							<div class="col-sm-12">
								<div style="padding: 15px 15px;">
									<div class="row">
										<div class="form-group">
											<label class="robo-16-500">HODO note</label>
											<textarea class="form-control mt-2" v-model="note" @change="err_note = ''"></textarea>
											<small class="text-warning">{{err_note}}</small>
										</div>
										<div align="right" class="form-group mt-3">
											<button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Bỏ qua</button>
											<button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit(id)">Thêm</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<div class="table-responsive">
								<table class="table table-vcenter table-mobile-md card-table table-scroll">
									<thead>
										<tr class="d-sm-flex">
											<th class="col-sm-3">Ngày giờ</th>
											<th class="col-sm-9">HODO note</th>
										</tr>
									</thead>
									<tbody v-if="notes && notes.count != 0">
										<tr class="border-top d-sm-flex" v-for="(note, index) in notes.data" :key="note">
											<td class="robo-16-400 col-sm-3">{{note && note.created_at ? formatDateDMY(note.created_at) : ''}}</td>
											<td class="col-sm-9">
												<pre class="border-0 bg-white robo-18-400" style="padding: 0;">{{note && note.notes}}</pre>
											</td>
										</tr>
									</tbody>
									<tbody v-if="!notes || (notes && notes.count == 0)">
										<tr>
											<td class="text-center" colspan="2">Không dữ liệu</td>
										</tr>
									</tbody>
								</table>
								<Pagination v-show="notes && notes.count" :items="notes" :current_page="current_page" @onRefresh="getPersonDiseaseNotes"></Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from '../../components/Pagination'
export default {
  name: 'ModelPersonDiseaseNotes',
  components: {Pagination},
  props: ['id_note'],
  data () {
    return {
      id: null,
      note: null,
      err_note: null,
      notes: null,
      current_page: 1
    }
  },
  watch: {
    id_note: function (_new, old) {
      this.id = _new
      this.getPersonDiseaseNotes()
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal--person-diseases-notes').on('hide.bs.modal', function (e) {
        self.note = ''
        self.err_note = ''
      })
    })
  },
  methods: {
    async onSubmit (id) {
      let self = this
      try {
        if (!self.note) {
          self.err_note = 'Vui lòng nhập HODO note'
        } else {
          self.err_note = ''
          var param = {
            notes: self.note
          }
          await self.$rf.getRequest('AdminRequest').postPersonDiseaseNotes(id, param).then(res => {
            self.getPersonDiseaseNotes()
            self.note = ''
            self.err_note = ''
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonDiseaseNotes () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var param = {
          limit: 10,
          sort: 'updated_at',
          sort_by: 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getPersonDiseaseNotes(self.id, param).then(res => {
          if (res && res.data) {
            self.notes = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>
<style scoped></style>