<template>
  <div
    class="modal fade"
    id="ModalCreateVoucher"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateVoucherLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalDoCreateLabel"
          >Thêm Voucher</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <div class="mb-3 row">
            <div class="row">
              <div class="form-check col-6">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="bacsi" @click="checkedInput()" checked>
                <label class="form-check-label robo-18-400" for="bacsi" checked>
                  Bác sĩ
                </label>
              </div>
              <div class="form-check col-6">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="phongkham" @click="checkedInput()">
                <label class="form-check-label robo-18-400" for="phongkham">
                  Phòng khám
                </label>
              </div>
            </div>
          </div>

          <div id="content-bacsi" class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Chọn bác sĩ </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="form.doctor_id">
                 <option class="robo-16-500" :value="null">Chọn bác sĩ</option>
                 <option class="robo-16-500" v-for="pack in doctor.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
          <div id="content-phongkham" class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Chọn phòng khám </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="form.clinic_id">
                 <option class="robo-16-500" :value="null">Chọn phòng khám</option>
                 <option class="robo-16-500" v-for="pack in clinic.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>

            <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Tên gói </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="form.package_id">
                 <option class="robo-16-500" :value="null">Chọn gói</option>
                 <option class="robo-16-500" v-for="pack in packages.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Loại </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="form.type">
                 <option class="robo-16-500" :value="null">Chọn Loại</option>
                 <option class="robo-16-500" :value="1">FREE</option>
                 <option class="robo-16-500" :value="2">PREMIUM</option>
               </select>
          </div>
          <div class="mb-3">
              <div class="row">
                   <label
                    for="name"
                    class="robo-18-400 mb-1 required"
                    >Khuyến mãi </label>
                  <div class="col-6">
                    <input
                    type="text"
                    class="form-control border"
                    placeholder="Số tháng cộng thêm"
                    v-model="form.months"
                    > 
                  </div>
                  <div class="col-6">
                    <input
                    type="text"
                    class="form-control border"
                    placeholder="Số tiền giảm"
                    v-model="form.value"
                    > 
                  </div>
              </div>
          </div>
           <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Số lượng </label>
            <input
              type="number"
              class="form-control border"
              placeholder="Nhập số lượng"
              
            >
          </div>
           <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Hiệu lực </label>
            <input
              type="text"
              class="form-control border"
              placeholder="Ngày hết hạn"
              v-model="form.expired_date"
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Tạo</button>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>

export default {
  name: 'ModalCreateVoucher',
  data () {
    return {
      clinic: [],
      loading: false,
      packages: [],
      packagesVoucher: [],
      doctor: [],
      form: {
        code: '',
        name: '',
        value: 0,
        months: 0,
        type: 1,
        status: 0,
        expired_date: 0,
        package_id: 0,
        doctor_id: 0,
        clinic_id: 0
      }
    }
  },
  mounted () {
    this.getPackages()
    this.getDoctors()
    this.getVoucher()
    this.getClinics()
  },
  methods: {
    checkedInput () {
      var bacsi = document.getElementById('bacsi')
      var phongkham = document.getElementById('phongkham')

      var contentBacsi = document.getElementById('content-bacsi')
      var contentPhongkham = document.getElementById('content-phongkham')

      contentPhongkham.style.display = 'none'
      contentBacsi.style.display = 'none'
      if (bacsi.checked) {
        contentBacsi.style.display = 'block'
        contentPhongkham.style.display = 'none'
      } else if (phongkham.checked) {
        contentBacsi.style.display = 'none'
        contentPhongkham.style.display = 'block'
      } else {
        contentPhongkham.style.display = 'none'
        contentBacsi.style.display = 'none'
      }
    },
    onSubmit () {
      let self = this
      if (!self.form.months) {
        alert('Vui lòng nhập bác sĩ')
        return
      }
      self.$emit('onCreate', self.form)
      self.form = {
        code: '',
        name: '',
        value: 0,
        months: 0,
        type: 1,
        status: 0,
        expired_date: 0,
        package_id: 0,
        doctor_id: 0,
        clinic_id: 0
      }
    },
    async getPackages () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: 50,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getPackages(params).then(res => {
          self.packages = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async getClinics () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: 50,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getClinics(params).then(res => {
          self.clinic = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    onDoctor () {
      document.getElementById('text1').display = 'block'
    },
    async getVoucher () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: `10`,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getVoucher(params).then(res => {
          self.packagesVoucher = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async getDoctors () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: 50,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getDoctors(params).then(res => {
          self.doctor = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    }
  }
}
    </script>

<style scoped>
</style>