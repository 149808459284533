<template>
	<div>
		<div class="modal fade" id="model-care-plane" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Điều trị</h5>
						<span class="btn-close-w-sm" data-dismiss="modal"></span>
					</div>
					<div class="modal-body">
						<div class="robo-16-500 mt-2">Thông tin điều trị tổng hợp</div>
						<div class="txt-pri mt-1 border-item">
							<input class="form-control" type="text" v-model="disease.treatment_summary">
							<span class="texr-red" v-if="!disease.treatment_summary && is_submit">Không để thông tin điều trị tổng hợp trống</span>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn bg-pri bd-pri text-white" data-dismiss="modal" style="padding: 12px 51px;" @click="onSubmit()">Update</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'ModalCarePlan',
  props: ['disease'],
  data () {
    return {
      is_submit: false
    }
  },
  mounted () {
  },
  methods: {
    onSubmit () {
      let self = this
      self.$emit('onSubmit', self.disease.id, self.disease.treatment_summary)
    }
  }
}
</script>
<style scoped>
</style>