<template>
  <div
    class="modal fade"
    id="ModalCreateBenefit"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateBenefitLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalDoCreateLabel"
          >Thêm Lợi ích</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Tên gói </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="slt_pack" @change="selectPack()">
                 <option class="robo-16-500" :value="null">Chọn gói</option>
                 <option class="robo-16-500" v-for="pack in packages.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1"
            >Mã </label>
            <input
              type="text"
              class="form-control border"
              readonly
              v-model="pack_code" disabled
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Chi tiết lợi ích </label>
            <textarea class="form-control" v-model="form.content" rows="3">

            </textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Tạo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalCreateBenefit',
  data () {
    return {
      loading: false,
      packages: [],
      pack_code: '',
      slt_pack: null,
      form: {
        package_id: 0,
        content: ''
      }
    }
  },
  mounted () {
    this.getPackages()
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.form.content) {
        alert('Vui lòng nhập chi tiết lợi ích gói')
        return
      }
      self.$emit('onCreate', self.form)
      self.form = {
        content: ''
      }
    },
    async getPackages () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: `10`,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getPackages(params).then(res => {
          self.packages = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    selectPack () {
      this.pack_code = this.slt_pack?.code
      this.form.package_id = this.slt_pack?.id
    }
  }
}
    </script>

<style scoped>
</style>