<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog" id="modal--care-team">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Care Team</h5>
						<span class="btn--close btn-sm" data-dismiss="modal" style="cursor: pointer;"><i class="fa fa-times fa-lg" aria-hidden="true"></i></span>
					</div>
					<div class="modal-body">
						<div class="row mt-2">
							<div class="col-sm-12">
								<div class="robo-20-500">Bác sĩ chính</div>
								<div style="padding: 15px 15px;" v-if="care_team_list && care_team_list.care_team_doctor">
									<div class="row" v-for="(care_team, index) in care_team_list.care_team_doctor" v-if="care_team.role == 1">
										<div class="d-flex py-1 align-items-center">
											<div style="min-width: 40px;">
												<span v-if="care_team && care_team.doctor && care_team.doctor.user && care_team.doctor.user.avatar" class="avatar avatar-lg avatar-rounded" :style="'background-image: url('+getImageURL(care_team.doctor.user.avatar)+')'"></span>
												<span v-if="!care_team.doctor || !care_team.doctor.user || (care_team.doctor && care_team.doctor.user && !care_team.doctor.user.avatar)" class="avatar avatar-lg avatar-rounded bg-secondary text-white">
													{{ care_team && care_team.doctor && care_team.doctor.name ? care_team.doctor.name.charAt(0) : '' }}
												</span>
											</div>
											<div class="ml-2 flex-fill w-50" style="cursor: pointer;">
												<p class="ml-2 p-0 m-0 text-truncate robo-16-500">
													{{care_team && care_team.doctor && care_team.doctor.name}}
												</p>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.chuc_vu}}</div>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.address}}</div>
											</div>
											<div class="ml-2 flex-fill w-25">
												<div class="robo-14-500 text-red text-truncate" v-if="care_team && care_team.is_accepted == 0">Chờ xác nhận</div>
												<div class="robo-14-500 text-success text-truncate" v-if="care_team && care_team.is_accepted == 1">Xác nhận</div>
											</div>
										</div>
									</div>
								</div>
								<div class="robo-20-500">Bác điều trị</div>
								<div style="padding: 15px 15px;" v-if="care_team_list && care_team_list.care_team_doctor">
									<div class="row" v-for="(care_team, index) in care_team_list.care_team_doctor" v-if="care_team.role == 4">
										<div class="d-flex py-1 align-items-center">
											<div style="min-width: 40px;">
												<span v-if="care_team && care_team.doctor && care_team.doctor.user && care_team.doctor.user.avatar" class="avatar avatar-lg avatar-rounded" :style="'background-image: url('+getImageURL(care_team.doctor.user.avatar)+')'"></span>
												<span v-if="!care_team.doctor || !care_team.doctor.user || (care_team.doctor && care_team.doctor.user && !care_team.doctor.user.avatar)" class="avatar avatar-lg avatar-rounded bg-secondary text-white">
													{{ care_team && care_team.doctor && care_team.doctor.name ? care_team.doctor.name.charAt(0) : '' }}
												</span>
											</div>
											<div class="ml-2 flex-fill w-50" style="cursor: pointer;">
												<p class="ml-2 p-0 m-0 text-truncate robo-16-500">
													{{care_team && care_team.doctor && care_team.doctor.name}}
												</p>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.chuc_vu}}</div>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.address}}</div>
											</div>
											<div class="ml-2 flex-fill w-25">
												<div class="robo-14-500 text-red text-truncate" v-if="care_team && care_team.is_accepted == 0">Chờ xác nhận</div>
												<div class="robo-14-500 text-success text-truncate" v-if="care_team && care_team.is_accepted == 1">Xác nhận</div>
											</div>
										</div>
									</div>
								</div>
								<div class="robo-20-500">Người hỗ trợ</div>
								<div style="padding: 15px 15px;" v-if="care_team_list && care_team_list.care_team_doctor">
									<div class="row" v-for="(care_team, index) in care_team_list.care_team_doctor" v-if="care_team.role == 2">
										<div class="d-flex py-1 align-items-center">
											<div style="min-width: 40px; cursor: pointer;">
												<span v-if="care_team && care_team.doctor && care_team.doctor.user && care_team.doctor.user.avatar" class="avatar avatar-lg avatar-rounded" :style="'background-image: url('+getImageURL(care_team.doctor.user.avatar)+')'"></span>
												<span v-if="!care_team.doctor || !care_team.doctor.user || (care_team.doctor && care_team.doctor.user && !care_team.doctor.user.avatar)" class="avatar avatar-lg avatar-rounded bg-secondary text-white">
													{{ care_team && care_team.doctor && care_team.doctor.name ? care_team.doctor.name.charAt(0) : '' }}
												</span>
											</div>
											<div class="ml-2 flex-fill w-50" style="cursor: pointer;">
												<p class="ml-2 p-0 m-0 text-truncate robo-16-500">
													{{care_team && care_team.doctor && care_team.doctor.name}}
												</p>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.chuc_vu}}</div>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.address}}</div>
											</div>
											<div class="ml-2 flex-fill w-25">
												<div class="robo-14-500 text-red text-truncate" v-if="care_team && care_team.is_accepted == 0">Chờ xác nhận</div>
												<div class="robo-14-500 text-success text-truncate" v-if="care_team && care_team.is_accepted == 1">Xác nhận</div>
											</div>
										</div>
									</div>
								</div>
								<div class="robo-20-500">Cơ sở y tế (Bác sĩ chủ cơ sở y tế)</div>
								<div style="padding: 15px 15px;" v-if="care_team_list && care_team_list.care_team_doctor">
									<div class="row" v-for="(care_team, index) in care_team_list.care_team_doctor" v-if="care_team.role == 3">
										<div class="d-flex py-1 align-items-center">
											<div style="min-width: 40px; cursor: pointer;">
												<span v-if="care_team && care_team.doctor && care_team.doctor.user && care_team.doctor.user.avatar" class="avatar avatar-lg avatar-rounded" :style="'background-image: url('+getImageURL(care_team.doctor.user.avatar)+')'"></span>
												<span v-if="!care_team.doctor || !care_team.doctor.user || (care_team.doctor && care_team.doctor.user && !care_team.doctor.user.avatar)" class="avatar avatar-lg avatar-rounded bg-secondary text-white">
													{{ care_team && care_team.doctor && care_team.doctor.name ? care_team.doctor.name.charAt(0) : '' }}
												</span>
											</div>
											<div class="ml-2 flex-fill w-50" style="cursor: pointer;">
												<p class="ml-2 p-0 m-0 text-truncate robo-16-500">
													{{care_team && care_team.doctor && care_team.doctor.name}}
												</p>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.chuc_vu}}</div>
												<div class="ml-2 robo-14-400 text-black-50 text-truncate">{{care_team && care_team.doctor && care_team.doctor.address}}</div>
											</div>
											<div class="ml-2 flex-fill w-25">
												<div class="robo-14-500 text-red text-truncate" v-if="care_team && care_team.is_accepted == 0">Chờ xác nhận</div>
												<div class="robo-14-500 text-success text-truncate" v-if="care_team && care_team.is_accepted == 1">Xác nhận</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</div>
					<div class="modal-footer">
            			<button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'ModalCareTeam',
  props: ['care_team'],
  data () {
    return {
      care_team_list: null
    }
  },
  watch: {
    care_team: function (_new, old) {
      this.care_team_list = _new
    }
  },
  mounted () {
  },
  methods: {
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    }
  }
}
</script>
<style scoped></style>