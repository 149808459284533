<template>
  <div
    class="modal fade"
    id="ModalUpdateVoucher"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalUpdateVoucherLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div
        class="modal-content"
        v-if="edit"
      >
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalUpdateVoucherLabel"
          >Chỉnh sửa</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Chọn bác sĩ </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="form.doctor_id">
                 <option class="robo-16-500" v-for="pack in doctor.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
           <div id="content-phongkham" class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Chọn phòng khám </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3" v-model="form.clinic_id">
                 <option class="robo-16-500" v-for="pack in clinic.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
           <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Tên gói </label>
               <select class="p-1 mr-1 form-select px-3 border mr-3">
                 <option selected class="robo-16-500" v-for="pack in packages.data" :value="pack" :key="pack.id">{{pack.name}}</option>
               </select>
          </div>
          <div class="mb-3">
              <div class="row">
                   <label
                    for="name"
                    class="robo-18-400 mb-1 required"
                    >Khuyến mãi </label>
                  <div class="col-6">
                    <input
                    type="number"
                    class="form-control border"
                    placeholder="Số tháng cộng thêm"
                    v-model="form.months"
                    > 
                  </div>
                  <div class="col-6">
                    <input
                    type="number"
                    class="form-control border"
                    placeholder="Số tiền giảm"
                    v-model="form.value"
                    > 
                  </div>
              </div>
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Hiệu lực </label>
            <input
              type="text"
              class="form-control border"
              placeholder="Ngày hết hạn"
              v-model="form.expired_date"
            >
          </div>
            
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Lưu</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalUpdateVoucher',
  props: ['edit'],
  data () {
    return {
      packages: [],
      doctor: [],
      packagesVoucher: [],
      clinic: [],
      form: {
        code: '',
        name: '',
        value: 0,
        months: 0,
        type: 1,
        status: 0,
        expired_date: 0,
        package_id: 0,
        doctor_id: 0,
        clinic_id: 0
      }
    }
  },
  mounted () {
    this.getPackages()
    this.getVoucher()
    this.getDoctors()
    this.getClinics()
  },
  watch: {
    edit (value) {
      console.log(value)
      if (!value) return
      this.form = {
        code: value.code,
        name: value.name,
        value: value.value,
        months: value.months,
        type: value.type,
        expired_date: value.expired_date
      }
    }
  },
  methods: {
    async getClinics () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: 50,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getClinics(params).then(res => {
          self.clinic = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async getVoucher () {
      let self = this
      self.loading = true
      try {
        self.page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 10,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.page
        }
        await self.$rf.getRequest('AdminRequest').getVoucher(params).then(res => {
          self.packagesVoucher = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    onSubmit () {
      let self = this
      if (!self.form.months) {
        alert('Nhập Khuyến mãi')
        return
      }
      if (self.form.status) {
        self.form.status = 1
      } else {
        self.form.status = 0
      }

      self.$emit('onUpdate', self.form)
      self.form = {
        package_months: 0,
        package_price: 0,
        combo_months: 0,
        combo_price: 0,
        status: 0,
        description: ''
      }
    },
    async getDoctors () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: `50`,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getDoctors(params).then(res => {
          self.doctor = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async getPackages () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: `10`,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getPackages(params).then(res => {
          self.packages = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    }
  }
}
    </script>

<style scoped>
.input-file {
  height: auto;
  width: 100%;
  line-height: 24px;
  margin-top: 20px;
}
.img-fixed {
  margin-bottom: -36px;
}
.checkbox-size {
  width: 18px;
  height: 18px;
  accent-color: #03267a;
}
</style>