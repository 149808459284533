<template>
    <div>
        <div class="modal" tabindex="-1" role="dialog" id="modal-result">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-pri">
                        <h5 class="modal-title" align="left">{{template_name}}</h5>
                        <span class="btn--close"></span>
                    </div>
                    <div class="modal-body">
                      <div class="row mb-3 mt-2">
                          <div>
                            <label for="" class="robo-16-500 text-gray-dark mb-2 required">Tên nhóm</label>
                            <input type="text" class="form-control robo-16-400 txt--black-gray" v-model="test_result_name"/>
                            <small class="text-red" v-if="!test_result_name">Vui lòng nhập tên nhóm</small>
                          </div>
                        </div>
                        <div class="mt-2 mb-3" v-for="(result, index) in results" :key="index" :class="index != 0 ? 'border-top' : ''">
                        	<div class="row mb-3 mt-2">
                    			<div>
                    				<label for="" class="robo-16-500 text-gray-dark mb-2 required">Chẩn đoán</label>
                    				<input type="text" class="form-control robo-16-400 txt--black-gray" v-model="result.exploration_name"/>
                            <small class="text-red" v-if="!result.exploration_name">{{result.error && result.error.exploration_name}}</small>
                    			</div>
                    		</div>
                    		<div class="row mb-3">
                    			<div>
                    				<label for="" class="robo-16-500 text-gray-dark mb-2 required">Kết luận</label>
                    				<textarea class="form-control robo-16-400 txt--black-gray" v-model="result.exploration_result" style="height: 200px;"></textarea>
                            <small class="text-red" v-if="!result.exploration_result">{{result.error && result.error.exploration_result}}</small>
                    			</div>
                    		</div>
                    		<div class="row mb-3">
                    			<div>
                    				<label for="" class="robo-16-500 text-gray-dark mb-2 required">Chi tiết</label>
                    				<textarea class="form-control robo-16-400 txt--black-gray" v-model="result.exploration_desc" style="height: 200px;"></textarea>
                            <small class="text-red" v-if="!result.exploration_desc">{{result.error && result.error.exploration_desc}}</small>
                    			</div>
                    		</div>
                        </div>
                        <div class="w-100 text-center">
                        	<button type="button" class="bg-white txt-pri robo-20-500 border-0 px-5" @click="addAResult()">
                        		<img src="../../../public/assets/images/icon/plus-circle.svg" class="mr-2">
                        		Thêm chẩn đoán khác
                        	</button>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center" @click="onSubmit()">
                        <button type="button" class="btn-l radius-0 bg-pri bd-pri text-white px-5"><span class="px-5">Lưu</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ModalCreateResult',
  props: ['template_name', 'person_id'],
  data () {
    return {
      results: [{
        exploration_desc: '',
        exploration_name: '',
        exploration_result: '',
        error: null,
        is_error: false
      }
      ],
      test_result_name: null
    }
  },
  watch: {
  },
  mounted () {
    let self = this
    window.$('#modal-result').on('hide.bs.modal', function (e) {
      self.emtyValidate()
    })
  },
  methods: {
    addAResult () {
      let self = this
      var param = {
        exploration_desc: '',
        exploration_name: '',
        exploration_result: '',
        error: null,
        is_error: false
      }
      self.results.push(param)
    },
    checkValidate () {
      let self = this
      self.results.forEach(function (element, index) {
        element.is_error = false
        var error = {
          exploration_desc: '',
          exploration_name: '',
          exploration_result: ''
        }
        if (!element.exploration_name) {
          error.exploration_name = 'Vui lòng nhập chẩn đoán'
          element.is_error = true
        }
        if (!element.exploration_desc) {
          error.exploration_desc = 'Vui lòng nhập chi tiết'
          element.is_error = true
        }
        if (!element.exploration_result) {
          error.exploration_result = 'Vui lòng nhập kết luận'
          element.is_error = true
        }
        if (element.is_error) {
          element.error = error
        }
      })
      var result = self.results.find(item => item.is_error === true)
      if (result) {
        return false
      } else {
        return true
      }
    },
    onSubmit () {
      let self = this
      try {
        if (self.checkValidate()) {
          var param = {
            person_diseases_id: self.$route?.params?.id,
            person_id: self.person_id,
            test_result_name: self.test_result_name || 'Chẩn đoán tham dò',
            type: 2
          }
          var params = {
            results: self.results,
            person_diseases_id: self.$route?.params?.id
          }
          self.$emit('onSubmitResults', param, params)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    emtyValidate () {
      let self = this
      self.results.forEach(function (element, index) {
        element.is_error = false
        element.error = null
      })
    }
  }
}
</script>
<style scoped="true">
  .input[type="text"] {
    color: #222222 !important;
    font-family: 'roboto';
    font-size: 16px;
    font-weight: 500;
  }
</style>