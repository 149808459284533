<template>
  <div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      id="modal--order-notes"
    >
      <div
        class="modal-dialog modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header pr-2">
            <h5 class="modal-title">Ghi chú</h5>
            <span
              class="btn--close btn-sm mr-2"
              data-dismiss="modal"
              style="cursor: pointer;"
            ><i
                class="fa fa-times fa-lg"
                aria-hidden="true"
              ></i></span>

          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px;">
                  <div class="row">
                    <div class="form-group">
                      <label class="robo-16-500">Nội dung</label>
                      <textarea
                        class="form-control mt-2"
                        v-model="note"
                        @change="err_note = ''"
                        rows="3"
                      ></textarea>
                      <small class="text-warning">{{err_note}}</small>
                    </div>
                    <div
                      align="right"
                      class="form-group mt-3"
                    >
                      <button
                        type="button"
                        class="btn bg-pri bd-pri text-white"
                        @click="onSubmit(id)"
                      >Thêm</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="row mt-2">
                <div class="col-3 font-weight-bold txt-pri">
                  Thông tin
                </div>
                <div class="col-9 font-weight-bold txt-pri">
                  Nội dung
                </div>
              </div>
              <div
                class="row py-3"
                v-for="(note) in notes.data"
                :key="note.id+'note'"
              >
                <div class="col-3">
                  <p class="mb-0 font-weight-bold">{{note && note.note_by ? note.note_by.name : ''}}</p>
                  <p class="mb-0 robo-12-400">{{note && note.created_at ? formatDateDMY(note.created_at) : ''}}</p>
                </div>
                <div class="col-9">
                  {{note && note.notes}}
                </div>
              </div>
              <div class="row">
                <div
                  class="col-12"
                  v-if="!notes || (notes && notes.count == 0)"
                >
                  <p class="text-center my-2">Chưa có ghi chú nào</p>
                </div>
                <div class="col-12">
                  <Pagination
                    v-show="notes && notes.count"
                    :items="notes"
                    :current_page="current_page"
                    @onRefresh="getOrderNotes"
                  ></Pagination>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '../../Pagination.vue'
export default {
  name: 'ModalOrderNotes',
  components: { Pagination },
  props: ['order_id'],
  data () {
    return {
      id: null,
      note: null,
      err_note: null,
      notes: [],
      current_page: 1
    }
  },
  watch: {
    order_id: function (_new, old) {
      this.id = _new
      this.getOrderNotes()
    }
  },
  mounted () {
    let self = this
    self.$nextTick(function () {
      window.$('#modal--order-notes').on('shown.bs.modal', function (e) {
        self.getOrderNotes()
        self.note = ''
        self.err_note = ''
      })
    })
  },
  methods: {
    async onSubmit (id) {
      let self = this
      try {
        if (!self.note) {
          self.err_note = 'Vui lòng nhập ghi chú'
        } else {
          self.err_note = ''
          var param = {
            notes: self.note
          }
          await self.$rf.getRequest('AdminRequest').createPackageOrderNotes(self.order_id, param).then(res => {
            self.getOrderNotes()
            self.note = ''
            self.err_note = ''
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getOrderNotes () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var param = {
          limit: 20,
          sort: 'updated_at',
          sort_by: 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getPackageOrderNotes(self.order_id, param).then(res => {
          if (res && res.data) {
            self.notes = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YY')
    }
  }
}
</script>
<style scoped></style>