<template>
	<table class="table table-vcenter table-mobile-md card-table table-scroll">
		<thead>
			<tr class="d-sm-flex">
				<th class="col-sm-1"></th>
				<th class="col-sm-4">Họ tên</th>
				<th class="col-sm-3">Số điện thoại</th>
				<th class="col-sm-4">Địa chỉ</th>
			</tr>
		</thead>
		<tbody class="h-50" v-if="doctors">
			<tr v-for="item in doctors.data" class="d-sm-flex">
				<td class="col-sm-1" data-label="">
					<input class="form-check-input" type="checkbox" id="inlineCheckbox1" :value="item" v-model="list_check">
				</td>
				<td class="col-sm-4" data-label="Họ tên">{{item && item.user && item.user.name ? item.user.name : ''}}</td>
				<td class="col-sm-3" data-label="Số điện thoại">{{item && item.phone ? item.phone : ''}}</td>
				<td data-label="Địa chỉ" class="text-nowrap text-truncate col-sm-4">{{item && item.address ? item.address : ''}}</td>
			</tr>
      <tr v-if="doctors && !doctors.count">
        <td colspan="4" class="text-center">Không thấy bác sĩ cần tìm kiếm</td>
      </tr>
		</tbody>
	</table>
</template>
<script>
export default {
  name: 'DoctorListInSendMess',
  props: ['doctors', 'list_doctor'],
  data () {
    return {
      list_check: []
    }
  },
  watch: {
    'list_check': {
      handler: function () {
        let self = this
        self.$emit('sendListFormDoctorToDad', self.list_check)
      },
      deep: true
    },
    list_doctor: function () {
      let self = this
      self.list_check = self.list_doctor
    },
    deep: true
  },
  mounted () {
    this.list_check = this.list_doctor
  },
  methods: {
  }
}
</script>
<style scoped></style>