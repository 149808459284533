<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog" id="modal--note">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Note</h5>
						<span class="btn--close"></span>
					</div>
					<div class="modal-body">
						<div class="row mt-2">
							<div class="col-sm-12">
								<div style="padding: 15px 15px;">
									<div class="row">
										<div class="form-group">
											<label class="robo-16-500">Số lần gọi</label>
											<input type="text" class="form-control mt-2" placeholder="" v-model="numberCall">
											<small v-if="!numberCall && is_submit" class="text-warning">Không để số lần gọi trống</small>
										</div>
										<div class="form-group mt-3">
											<label class="robo-16-500">Nội dung</label>
											<textarea class="form-control mt-2 w-100" v-model="content"></textarea>
											<span v-if="!content && is_submit" class="text-warning">Không để nội dung trống</span>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</div>
					<div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
						<button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit()">Update</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'ModelNotePersons',
  props: ['note_edit'],
  data () {
    return {
      numberCall: '',
      content: '',
      is_submit: false
    }
  },
  watch: {
    note_edit: {
      handler: function () {
        let self = this
        if (self.note_edit) {
          var str = self.note_edit ? JSON.parse(self.note_edit.data) : ''
          self.numberCall = str && str.number_call ? str.number_call : ''
          self.content = str && str.content ? str.content : ''
        } else {
          self.numberCall = ''
          self.content = ''
        }
      },
      deep: true
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      // window.$('#modal--note').on('show.bs.modal', function (e) {
      //   if (self.note_edit) {
      //     var str = self.note_edit ? JSON.parse(self.note_edit.data) : ''
      //     self.numberCall = str && str.number_call ? str.number_call : ''
      //     self.content = str && str.content ? str.content : ''
      //   }
      // })
      window.$('#modal--note').on('hide.bs.modal', function (e) {
        if (!self.note_edit) {
          self.numberCall = ''
          self.content = ''
        }
      })
    })
  },
  methods: {
    async onSubmit () {
      let self = this
      try {
        self.is_submit = true
        if (this.numberCall && this.content) {
          var arr = {
            number_call: self.numberCall,
            content: self.content
          }
          var param = {
            data: JSON.stringify(arr)
          }
          if (self.note_edit) {
            param.id = self.note_edit.id
            param.person_id = self.note_edit.person_id
            self.$emit('onSubmitNoteUpdate', param)
            self.is_submit = false
          } else {
            self.$emit('onSubmitNote', param)
            self.is_submit = false
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style scoped></style>