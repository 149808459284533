<template>
  <div>
    <ul class="nav nav-tabs txt-pri">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isRouteNames(['PackagesManagement']) ? 'active' : ''"
          href="javascript:;"
          @click="goTo(1)"
        >Danh sách gói</a>
      </li>
       <li class="nav-item">
        <a
          class="nav-link"
          :class="isRouteNames(['ComboManagement']) ? 'active' : ''"
          href="javascript:;"
          @click="goTo(2)"
        >Danh sách combo</a>
      </li>
        <li class="nav-item">
        <a
          class="nav-link"
          :class="isRouteNames(['MemberPackageBenefit']) ? 'active' : ''"
          href="javascript:;"
          @click="goTo(3)"
        >Danh sách lợi ích</a>
      </li>
        <li class="nav-item">
        <a
          class="nav-link"
          :class="isRouteNames(['VoucherManagement']) ? 'active' : ''"
          href="javascript:;"
          @click="goTo(4)"
        >Vouchers</a>
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          href="#"
        >Link</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link disabled"
          href="#"
        >Disabled</a>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
  data () {
    return {
      current_route_name: null
    }
  },
  mounted () {
    let self = this
    self.current_route_name = self.$router.currentRoute.name
  },
  methods: {
    goTo (type) {
      if (type === 1) this.$router.push({name: 'PackagesManagement'}).catch((e) => {})
      if (type === 2) this.$router.push({name: 'ComboManagement'}).catch((e) => {})
      if (type === 3) this.$router.push({name: 'MemberPackageBenefit'}).catch((e) => {})
      if (type === 4) this.$router.push({name: 'VoucherManagement'}).catch((e) => {})
    },
    isRouteNames (routeNames = []) {
      let self = this
      return routeNames.includes(self.current_route_name)
    }
  }
}
</script>

<style scoped>
a {
    font-size: 16px;
}
a.active {
    color: #20419B !important;
    font-weight: 500 !important;
    font-size: 18px;
}
</style>