<template>
  <div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      id="modal--service"
    >
      <div
        class="modal-dialog modal-fullscreen"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dịch vụ</h5>
            <button
              type="button"
              class="close btn bg-pri bd-pri text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="robo-24-500"
              >&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-2">
              <label
                for=""
                class="robo-18-500 mb-1 required"
              >Cơ sở y tế</label>
              <v-select
                v-if="clinics && clinics.data"
                class="mt-2"
                :options="clinics.data"
                label="item_label"
                placeholder="Chọn cơ sở y tế"
                :filterable="false"
                @open="onOpen"
                @close="onClose"
                v-model="clinic_item"
              
                @search="searchClinic"
                @change="error_clinic = ''"
              >
                <div slot="no-options">Not found!</div>
                <template v-slot:option="item">
                  <span class="font-weight-bold">{{item.name}} - {{item.phone}}</span>
                </template>
                <template #list-footer>
                  <li
                    ref="load"
                    v-show="hasNextPage"
                  ></li>
                </template>
              </v-select>
              <small class="form-text text-muted">{{error_clinic}}</small>
            </div>
            <div class="form-group mb-3">
              <label
                for=""
                class="robo-18-500 mb-1"
              >Loại dịch vụ</label>
              <v-select
                multiple
                v-if="categories && categories.data"
                class="mt-2 v-selected-list-scroll"
                :options="categories.data"
                label="name"
                placeholder="Chọn phân loại dịch vụ"
                :filterable="false"
                v-model="selected_categories"
                transition=""
                @search="searchCategories"
                @open="onOpenCategories"
                @close="onCloseCategories"
              >
                <div slot="no-options">Không tìm thấy!</div>
                <template #list-footer>
                  <li
                    ref="load"
                    v-show="hasNextPageCategories"
                  ></li>
                </template>
              </v-select>
            </div>
            <div class="form-group mb-2">
              <label
                for=""
                class="robo-18-500 mb-1 required"
              >Tên dịch vụ</label>
              <input
                type="text"
                class="form-control"
                v-model="form.name"
                @change="error_name = ''"
              >
              <small class="form-text text-muted">{{error_name}}</small>
            </div>
            <div class="form-group mb-2">
              <label
                for=""
                class="robo-18-500 mb-1 required"
              >Mô tả ngắn</label>
              <textarea
                class="form-control h-100"
                v-model="form.desc_short"
                @change="error_desc = ''"
                rows="4"
              ></textarea>
              <small class="form-text text-muted">{{error_desc}}</small>
            </div>
            <div class="form-group mb-2">
              <label
                for=""
                class="robo-18-500 mb-1"
              >Mô tả đầy đủ</label>
              <textarea
                class="form-control h-100"
                v-model="form.desc_full"
                rows="20"
              ></textarea>
              <small class="form-text text-muted"></small>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group mb-2">
                  <label
                    for=""
                    class="robo-18-500 mb-1"
                  >Giá tiền</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="form.price"
                  >
                  <small class="form-text text-muted"></small>
                </div>
              </div>
              <div class="col-sm-3 mb-2">
                <div class="form-group">
                  <label
                    for=""
                    class="robo-18-500 mb-1"
                  >Đơn vị tiền</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.currency"
                  >
                  <small class="form-text text-muted"></small>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label
                    for=""
                    class="robo-18-500 mb-1 d-block"
                  >Hiển thị trên mobile</label>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="statusMob"
                      value="1"
                      v-model="form.status"
                    >
                    <label class="form-check-label">Hiện</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="statusMob"
                      value="0"
                      v-model="form.status"
                    >
                    <label class="form-check-label"> Ẩn</label>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    for=""
                    class="robo-18-500 mb-1 d-block"
                  >Hiển thị trên web</label>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="stateWeb"
                      value="1"
                      v-model="form.state"
                    >
                    <label class="form-check-label">Hiện</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="stateWeb"
                      value="0"
                      v-model="form.state"
                    >
                    <label class="form-check-label">Ẩn</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="robo-18-500 mb-1">Ảnh dịch vụ trên web</label><br>
                  <input
                    type="file"
                    class="form-control-file"
                    accept=".jpeg,.jpg,.gif,.png"
                    @change="changeBannerWeb($event)"
                  >
                  <div
                    class="mr-2 thread-medias-img border bd-muted mt-2"
                    v-if="form && form.banner_web && is_change_web"
                    :style="`background-image: url('${getBase64URL(form.banner_web)}');`"
                  >
                  </div>
                  <div
                    class="mr-2 thread-medias-img border bd-muted mt-2"
                    v-if="form && form.banner_web && !is_change_web"
                    :style="`background-image: url('${getImageURL(form.banner_web)}');`"
                  >
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="robo-18-500 mb-1">Ảnh dịch vụ trên mobile (320x120)</label><br>
                  <input
                    type="file"
                    class="form-control-file"
                    accept=".jpeg,.jpg,.gif,.png"
                    @change="changeBannerApp($event)"
                  >
                  <div
                    class="mr-2 thread-medias-img border bd-muted mt-2"
                    v-if="form && form.banner_app && is_change_app"
                    :style="`background-image: url('${getBase64URL(form.banner_app)}');`"
                  >
                  </div>
                  <div
                    class="mr-2 thread-medias-img border bd-muted mt-2"
                    v-if="form && form.banner_app && !is_change_app"
                    :style="`background-image: url('${getImageURL(form.banner_app)}');`"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >Đóng</button>
            <button
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmit()"
            >Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'ModalService',
  props: ['edit'],
  components: { vSelect },
  data () {
    return {
      form: {
        clinic_id: '',
        name: '',
        banner_web: '',
        banner_app: '',
        desc_short: '',
        desc_full: '',
        price: '',
        currency: 'VND',
        status: 1,
        state: 1
      },
      id: '',
      error_clinic: '',
      error_name: '',
      error_desc: '',
      is_submit: false,
      clinics: null,
      search: '',
      observer: null,
      observerCategories: null,
      clinic_item: null,
      is_change_web: true,
      is_change_app: true,
      categories: null,
      loading: false,
      selected_categories: []
    }
  },
  computed: {
    hasNextPage () {
      let self = this
      if (self.clinics.total > self.clinics.count) {
        return true
      }
      return false
    },
    hasNextPageCategories () {
      let self = this
      if (self.categories.total > self.categories.count) {
        return true
      }
      return false
    }
  },
  watch: {
    edit: function (_new, old) {
      let self = this
      if (_new) {
        self.form = _new
        self.clinic_item = _new.clinic
        self.clinic_item.item_label = self.clinic_item.name + ' - ' + self.clinic_item.phone
        self.is_change_web = false
        self.is_change_app = false
        if (_new.categories) {
          self.selected_categories = _new.categories
        }
      } else {
        self.emtyValue()
      }
    }
  },
  mounted () {
    let self = this
    self.observer = new IntersectionObserver(self.infiniteScroll)
    self.observerCategories = new IntersectionObserver(self.infiniteScrollCategories)
    self.getClinics()
    self.getServiceCategory()
    window.$(document).ready(function () {
      window.$('#modal--service').on('hide.bs.modal', function (e) {
        self.emtyValue()
      })
    })
  },
  methods: {
    async getServiceCategory (limit = 50, kw) {
      let self = this
      self.loading = true
      let params = {
        limit: limit
      }
      if (kw) {
        params.search = kw
      }
      await self.$rf.getRequest('AdminRequest').getServiceCategory(params).then((rs) => {
        self.categories = rs.data
      }).finally(() => {
        self.loading = true
      })
    },
    searchCategories (kw) {
      this.getServiceCategory(50, kw)
    },
    async infiniteScrollCategories ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var limit = this.categories.count + 20
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.getServiceCategory(limit)
      }
    },
    emtyValue () {
      let self = this
      self.form = {
        clinic_id: '',
        name: '',
        banner_web: '',
        banner_app: '',
        desc_short: '',
        desc_full: '',
        price: '',
        currency: 'VND',
        status: 1,
        state: 1
      }
      self.clinic_item = null
      self.selected_categories = []
    },
    async onSubmit () {
      let self = this
      try {
        if (self.checkValidate()) {
          if (self.clinic_item) {
            self.form.clinic_id = self.clinic_item.id
          }
          let form = self.form
          if (self.selected_categories && self.selected_categories.length) {
            let selected_categories = self.selected_categories

            let service_category_ids = []
            selected_categories.forEach(c => {
              service_category_ids.push(c.id)
            })
            form.service_category_ids = service_category_ids
          }
          var formData = new FormData()
          for (const [key, value] of Object.entries(form)) {
            if (key !== 'clinic') {
              formData.append(key, value)
            }
          }
          if (self.edit) {
            self.$emit('onSubmitUpdateService', form.id, formData)
          } else {
            self.$emit('onSubmitAddService', formData)
          }
          console.log(form)
        }
      } catch (e) {
        console.log(e)
      }
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    checkValidate () {
      let self = this
      let result = true
      if (!self.clinic_item) {
        self.error_clinic = self.$t('workspace_setting.lbl_pls_choose_workspace')[self.$orgModel]
        result = false
      }
      if (!self.form.name) {
        self.error_name = 'Vui lòng nhập tên dịch vụ'
        result = false
      }
      if (!self.form.desc_short) {
        self.error_desc = 'Vui lòng nhập mô tả ngắn'
        result = false
      }
      return result
    },
    async getClinics (num) {
      try {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        self.params = {
          limit: num || 20,
          search: self.search,
          sort: 'created_at',
          sort_by: 'desc'
        }
        const result = await self.$rf.getRequest('AdminRequest').getClinics(self.params)
        self.clinics = result.data
        self.clinics.data.map((x) => {
          x.item_label = x.name + ' - ' + x.phone
        })
      } catch (e) {
        console.log(e)
      }
    },
    async onOpenCategories () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observerCategories.observe(this.$refs.load)
      }
    },
    onCloseCategories () {
      this.observerCategories.disconnect()
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var limit = this.clinics.count + 20
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.getClinics(limit)
      }
    },
    searchClinic (search) {
      let self = this
      if (search) {
        self.search = search
      } else {
        self.search = ''
      }
      self.getClinics()
    },
    changeBannerWeb (event) {
      let self = this
      // let _files = !!self.topic_medias && !!self.topic_medias.length ? self.topic_medias : []
      let item = event.target.files[0]
      if (item) {
        if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
          self.form.banner_web = item
          self.is_change_web = true
        } else {
          self.$toast.open({
            message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
            type: 'warning'
          })
          self.form.banner_web = null
        }
      }
    },
    changeBannerApp (event) {
      let self = this
      // let _files = !!self.topic_medias && !!self.topic_medias.length ? self.topic_medias : []
      let item = event.target.files[0]

      if (item) {
        if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
          self.form.banner_app = item
          self.is_change_app = true
        } else {
          self.$toast.open({
            message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
            type: 'warning'
          })
          self.form.banner_app = null
        }
      }
      event.target.files.forEach(item => {
        if (item) {
          if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
            self.form.banner_app = item
            self.is_change_app = true
          } else {
            self.$toast.open({
              message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
              type: 'warning'
            })
            self.form.banner_app = null
          }
        }
      })
    }
  }
}
</script>
<style scoped>
.thread-medias-img {
  width: 126px;
  height: 126px;
  border-radius: 12px;
  background-size: cover;
  cursor: pointer;
}
input[type="number"],
textarea {
  height: 60px;
  color: #20419B;
  font-weight: 500px;
  font-size: 18px;
}
.vs__selected_options {
  max-height: 52px !important;
  overflow: auto !important;
}
</style>