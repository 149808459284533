<template>
  <div
    class="modal fade"
    id="ModalUpdatePack"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalUpdatePackLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div
        class="modal-content"
        v-if="edit"
      >
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalUpdatePackLabel"
          >Cập nhật thông tin gói</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Tên gói </label>
            <input
              type="text"
              class="form-control border"
              v-model="form.name"
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Mã gói </label>
            <input
              type="text"
              class="form-control border"
              v-model="form.code"
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Giá mỗi tháng </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.price_per_month"
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Mô tả </label>
            <textarea
              class="form-control"
              v-model="form.description"
              rows="3"
            >

            </textarea>
          </div>
          <div class="row mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1"
            >Icon gói </label><br>
            <div class="col-10">
              <input
                type="file"
                ref="fileupload"
                v-on:change="onImageChange"
                class="form-control input-file robo-18-400"
              >
            </div>
            <div
              class="col-2"
              v-if="image"
            >
              <img
                :src="image"
                class="img-fixed"
                height="75"
                width="58"
              >
            </div>
            <div
              class="col-2"
              v-if="!image && edit && edit.package_icon"
            >
              <img
                :src="getUrl(edit.package_icon)"
                class="img-fixed"
                height="75"
                width="58"
              >
            </div>
            <!-- <div class="col-md-3">
              <button class="btn btn-success btn-block" @click="uploadImage">Upload Image</button>
            </div> -->
          </div>
          <div class="mb-3">
               <label
              for="name"
              class="robo-16-500 mb-1"
            >Trạng thái sử dụng</label>
          </div>
          <div class="mb-3 d-flex">
            <input
              class="checkbox-size"
              type="checkbox"
              id="checkbox"
              v-model="form.status"
            >
            <label
              for="checkbox"
              class="robo-16-400 ml-2 mb-1"
            >
              Active
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Tạo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import appUtils from '../../../../utils/appUtils'
export default {
  name: 'ModalUpdatePack',
  props: ['edit'],
  data () {
    return {
      image: '',
      form: {
        code: '',
        name: '',
        description: '',
        status: 1,
        price_per_month: 0,
        package_icon: ''
      },
      icon: null
    }
  },
  mounted () {
  },
  watch: {
    edit (value) {
      console.log(value)
      if (!value) return
      this.form = {
        code: value.code,
        name: value.name,
        description: value.description,
        status: value.status,
        price_per_month: value.price_per_month,
        package_icon: value.package_icon
      }
    }
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.form.name) {
        alert('Vui lòng nhập tên gói')
        return
      } else if (!self.form.code) {
        alert('Vui lòng nhập mã gói')
        return
      }
      if (self.icon) {
        self.form.package_icon = self.icon
      }
      if (self.form.status) {
        self.form.status = 1
      } else {
        self.form.status = 0
      }
      let formData = new FormData()
      for (let key in self.form) {
        formData.append(key, self.form[key])
      }
      self.$emit('onUpdate', formData)
      self.form = {
        code: '',
        name: '',
        description: '',
        status: 1,
        price_per_month: 0,
        package_icon: ''
      }
      this.$refs.fileupload.value = null
    },
    onImageChange (e) {
      let self = this
      self.icon = e.target ? e.target.files[0] : ''
      self.image = e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : ''
    },
    getUrl (path) {
      return appUtils.getImageURL(path)
    }
  }
}
    </script>

<style scoped>
.input-file {
  height: auto;
  width: 100%;
  line-height: 24px;
  margin-top: 20px;
}
.img-fixed {
  margin-bottom: -36px;
}
.checkbox-size {
  width: 18px;
  height: 18px;
  accent-color: #03267a;
}
</style>