<template>
  <div>
    <ul class="nav nav-tabs txt-pri">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isRouteNames(['ServicesOrders']) ? 'active' : ''"
          href="javascript:;"
          @click="goTo(1)"
        >Đơn hàng dịch vụ</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link disabled"
          href="javascript:;"
          @click="goTo(2)"
        >Đơn hàng vật phẩm</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isRouteNames(['MemberPackOrders']) ? 'active' : ''"
          href="javascript:;"
          @click="goTo(3)"
        >Đơn hàng member</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
  data () {
    return {
      current_route_name: null
    }
  },
  mounted () {
    let self = this
    self.current_route_name = self.$router.currentRoute.name
  },
  methods: {
    goTo (type) {
      if (type === 1) this.$router.push({ name: 'ServicesOrders' }).catch((e) => { })
      if (type === 3) this.$router.push({ name: 'MemberPackOrders' }).catch((e) => { })
    },
    isRouteNames (routeNames = []) {
      let self = this
      return routeNames.includes(self.current_route_name)
    }
  }
}
</script>

<style scoped>
a {
  font-size: 16px;
}
a.active {
  color: #20419B !important;
  font-weight: 500 !important;
  font-size: 18px;
}
</style>