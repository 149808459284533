<template>
    <div id="notification_tab">
        <div>
            <div class="card border-0">
                <div class="card-body card-body-scrollable card-body-scrollable-shadow p-0" style="background: #F4F6FA;">
                    <div class="notification-tab">
                      <div class="noti-close" @click="closeNoti()"></div>
                      <div v-for="noti in notis.data" :key="noti.id" :style="noti.id === notis.data[0].id ? 'margin: 24px 16px 11px 14px;' : 'margin: 14px 16px 11px 14px;'" style="margin: 14px 16px 11px 14px; border-radius: 6px;">
                      		<div class="card-noti" @click="goToNotification(noti)">
                      			<div class="d-flex pb-1 border-bottom justify-content-between">
                      				<div class="d-flex align-items-center">
                      					<div :class="noti ? getTypeNoti(noti).noticlass : 'noti-default noti-alter'"></div>
                      					<div class="ml-2 d-flex align-items-center robo-16-500 txt-pri">
                      						<span>{{noti && noti.data && noti.data.title}}</span>
                      					</div>
                      					<div class="ml-2 d-flex align-items-center badge bg-red"></div>
                      				</div>
                      				<div class="ml-2 d-flex align-items-center justify-content-end robo-14-400 text-black-50">{{formatDateAgo(noti.created_at)}}</div>
                      			</div>
                      			<div style="padding: 10px 0 10px 0;">
                      				<div>{{noti && noti.data && noti.data.body}}</div>
                      			</div>
                      		</div>
                      	</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mixinUser } from '../../utils/mixinUser'
import appUtils from '../../utils/appUtils'
export default {
  name: 'NotificationListingAdmin',
  mixins: [mixinUser],
  props: ['notis', 'loading'],
  data () {
    return {
      appUtils,
      user: null,
      currentRouteName: '',
      loading_content: false,
      notifications: [],
      person: [],
      doctor: null,
      clinic: null
    }
  },
  watch: {
    $route (to, from) {
      let self = this
      if (self.currentRouteName === to.name) {
        self.$router.go()
      } else {
        self.currentRouteName = to.name
      }
    }
  },
  computed: {
    hasNextPage () {
      let self = this
      if (self.notis) {
        return self.notis.count < self.notis.total
      }
    }
  },
  async mounted () {
    let self = this
    self.loading_content = true
    setTimeout(() => {
      self.loading_content = false
    }, 1000)
    // await self.getCurrentUser()
  },
  methods: {
    sortNoti (notis) {
      // console.log(notis)
      return notis.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at)
      })
    },
    getTitle (noti_id) {
      let self = this
      let result = self.notifications.find(noti => noti.id === noti_id)
      return result ? result.title : ''
    },
    getTypeNoti (noti) {
      let array = [
        {noticlass: 'noti-default noti-circle', name: 'Doctor Circle'},
        {noticlass: 'noti-default noti-appointment', name: 'Lịch làm việc'},
        {noticlass: 'noti-default noti-request', name: 'Yêu cầu khám'},
        {noticlass: 'noti-default noti-alter', name: 'Thông báo'},
        {noticlass: 'noti-default noti-admin', name: 'Thông báo từ HODO'},
        {noticlass: 'noti-default noti-request', name: 'Chỉ dẫn'},
        {noticlass: 'noti-default noti-invoice', name: 'Hoàn tất thanh toán'},
        {noticlass: 'noti-default noti-appointment', name: 'Xác nhận lịch hẹn'},
        {noticlass: 'noti-default noti-request', name: 'Phân công hỗ trợ'},
        {noticlass: 'noti-default noti-cancer', name: ''},
        {noticlass: 'noti-default noti-topic', name: ''}
      ]
      if (noti) {
        if (noti.type.indexOf('NewAppointmentPatientNotify') > -1) {
          return array[1]
        } else if (noti.type.indexOf('CampaignNotify') > -1) {
          return array[4]
        } else if (noti.type.indexOf('CsNewPersonDiseasesNotify') > -1) {
          return array[9]
        } else {
          return array[3]
        }
      }
    },
    closeNoti () {
      this.$emit('onCloseNoti', false)
    },
    formatDateAgo (date) {
      return window.moment(date, 'YYYY-MM-DD hh:mm:ss').fromNow()
    },
    async goToNotification (noti) {
      let self = this
      self.$emit('onCloseNoti', false)
      let now = window.moment().unix()
      if (noti) {
        if (noti.type.indexOf('CsNewServiceOrderNotify') > -1) {
          self.$router.push({ path: `/admin/order` }).catch(_ => { this.$router.go() })
        }
        if (noti.type.indexOf('CsNewPersonDiseasesNotify') > -1) {
          self.$router.push({ path: `/admin/diseases/${noti.data.person_diseases_id}/detail?t=${now}` }).catch(_ => { this.$router.go() })
        }
      }
    }
  }
}
</script>
<style scoped>
  .noti-default {
    height: 28px;
    width: 28px;
    background-size: 28px;
  }
  .noti-circle {
    background-image: url('../../../public/assets/images/icon/icon_circle_blue.svg');
  }
  .noti-appointment {
    background-image: url('../../../public/assets/images/icon/appointment.svg');
  }
  .noti-request {
    background-image: url('../../../public/assets/images/icon/request.svg');
  }
  .noti-alter {
    background-image: url('../../../public/assets/images/icon/navbar/Notification/def.svg');
  }
  .noti-admin {
    background-image: url('../../../public/assets/images/icon/noti_admin.svg');
  }
  .noti-invoice {
    background-image: url('../../../public/assets/images/icon/invoice.svg');
  }
  .noti-cancer {
    background-image: url('../../../public/assets/images/icon/icon-cancer.svg');
  }
  .noti-topic {
    background-image: url('../../../public/assets/images/icon/icon-topic.svg');
  }
  .noti-close {
    height: 20px;
    width: 20px;
    background-size: 20px;
    background-image: url('../../../public/assets/images/icon/icon_close.svg');
    margin: 42px 0px 0px 24px;
    cursor: pointer;
  }
  .card-noti {
    background: #FFFFFF; 
    padding: 4px 7px; 
    border-radius: 6px; 
    cursor: pointer;
  }
</style>