<template>
  <div
    class="modal fade"
    id="ModalCreatePack"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreatePackLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalCreatePackLabel"
          >Tạo gói</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Tên gói</label>
            <input
              type="text"
              class="form-control border"
              v-model="form.name"
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Mã gói </label>
            <input
              type="text"
              class="form-control border"
              v-model="form.code"
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Giá mỗi tháng </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.price_per_month"
            >
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Mô tả </label>
            <textarea class="form-control" v-model="form.description" rows="3">

            </textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Tạo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalCreatePack',
  data () {
    return {
      form: {
        code: '',
        name: '',
        description: '',
        status: 1,
        price_per_month: 0
      }
    }
  },
  mounted () {
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.form.name) {
        alert('Vui lòng nhập tên gói')
        return
      } else if (!self.form.code) {
        alert('Vui lòng nhập mã gói')
        return
      }
      self.$emit('onCreate', self.form)
      self.form = {
        code: '',
        name: '',
        description: '',
        status: 1,
        price_per_month: 0
      }
    }
  }
}
    </script>

<style scoped>
</style>