<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog" id="modal--permision">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Phân quyền</h5>
						<span class="btn--close"></span>
					</div>
					<div class="modal-body">
						<div class="row mt-2">
							<div class="col-sm-12">
								<div style="padding: 15px 15px;">
									<div class="row">
										<div class="form-group">
											<label class="robo-16-500">Tên phân quyền</label>
											<input type="text" class="form-control mt-2" placeholder="" v-model="name">
											<small v-if="!name && is_submit" class="text-warning">Không để tên phân quyền trống</small>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</div>
					<div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
						<button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit()">Gửi</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'ModelPermision',
  props: ['note_edit'],
  data () {
    return {
      name: '',
      is_submit: false
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal--permision').on('hide.bs.modal', function (e) {
        self.name = ''
        self.is_submit = false
      })
    })
  },
  methods: {
    async onSubmit () {
      let self = this
      try {
        self.is_submit = true
        if (this.name) {
          var param = {
            name: self.name
          }
          self.$emit('onSubmitPermision', param)
          self.is_submit = false
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style scoped></style>