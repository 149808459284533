<template>
    <div>
        <div class="modal" tabindex="-1" role="dialog" id="modal-create-test">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-pri">
                        <h5 class="modal-title" align="left">{{template_name}}</h5>
                        <span class="btn--close"></span>
                    </div>
                    <div class="modal-body">
                        <div class="mt-2 mb-3">
                            <div class="row">
                                <div class="col-sm-1 pr-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2"></label>
                                </div>
                                <div class="col-11">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2 required">Tên xét nghiệm</label>
                                    <input type="text" class="form-control robo-16-500 text-gray-dark" v-model="test_result_name" />
                                    <small class="text-red" v-if="is_submit && !test_result_name"> Không để tên xét nghiệm trống</small>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-1 pr-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2"></label>
                                </div>
                                <div class="col-sm-3 pr-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2 required">Chỉ số</label>
                                </div>
                                <div class="col-sm-2 pl-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2 required">Kết quả</label>
                                </div>
                                <div class="col-sm-2 pl-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2 required">Đơn vị</label>
                                </div>
                                <div class="col-sm-2 pl-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2 required">Trên</label>
                                </div>
                                <div class="col-sm-2 pl-3">
                                    <label for="" class="robo-16-500 text-gray-dark mb-2 required">Dưới</label>
                                </div>
                            </div>
                            <div v-if="test_items_list">
                                <div class="row mb-3" v-for="(test_item, index) in test_items_list" :key="index">
                                    <div class="col-sm-1 pr-3">
                                        <div class="mt-2" align="right"><img src="../../../public/assets/images/icon/icon-trash.svg" style="cursor: pointer;" @click="deleteTestsItems(index)"></div>
                                    </div>
                                    <div class="col-sm-3 pr-3">
                                        <input type="text" v-model="test_item.test_name" class="form-control robo-16-400 txt--black-gray" />
                                        <!-- {{getError(index)}} -->
                                        <small class="text-red" v-if="!test_item.test_name"> {{test_item.error && test_item.error.test_name}}</small>
                                    </div>
                                    <div class="col-sm-2 pl-3">
                                        <input type="number" step="any" class="form-control robo-16-400 txt--black-gray" min="0" v-model="test_item.test_value" />
                                        <small class="text-red" v-if="!test_item.test_value"> {{test_item.error && test_item.error.test_value}}</small>
                                    </div>
                                    <div class="col-sm-2 pl-3">
                                        <input type="text" v-model="test_item.test_unit" class="form-control robo-16-500 txt-pri" />
                                        <small class="text-red" v-if="!test_item.test_unit"> {{test_item.error && test_item.error.test_unit}}</small>
                                    </div>
                                    <div class="col-sm-2 pl-3">
                                        <input type="number" step="any" class="form-control robo-16-400 txt--black-gray" min="0" v-model="test_item.upper_threshold" />
                                        <small class="text-red" v-if="!test_item.upper_threshold"> {{test_item.error && test_item.error.upper_threshold}}</small> <br>
                                        <small class="text-red"> {{test_item.error && test_item.error.error_threshold}}</small>
                                    </div>
                                    <div class="col-sm-2 pl-3">
                                        <input type="number" step="any" class="form-control robo-16-400 txt--black-gray" min="0" v-model="test_item.lower_threshold" />
                                        <small class="text-red" v-if="!test_item.lower_threshold"> {{test_item.error && test_item.error.lower_threshold}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 text-center">
                                <button type="button" class="bg-white txt-pri robo-20-500 border-0 px-5" @click="addTestsItems(template_id)">
                                    <img src="../../../public/assets/images/icon/plus-circle.svg" class="mr-2"> Thêm
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                        <button type="button" class="btn-l radius-0 bg-pri bd-pri text-white px-5" @click="onSubmitTests()"><span class="px-5">Lưu</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ModalCreateTest',
  props: ['template_id', 'template_name', 'person_id', 'template_items'],
  data () {
    return {
      test_result_name: '',
      is_submit: false,
      test_items_list: null
    }
  },
  watch: {
    template_items: function (_new, old) {
      this.test_items_list = _new
    },
    template_name: function (_new, old) {
      this.test_result_name = _new
    }
  },
  mounted () {
    let self = this
    window.$('#modal-create-test').on('hide.bs.modal', function (e) {
      self.is_submit = false
    })
    // window.$('#modal-create-test').on('show.bs.modal', function (e) {
    //   self.test_items_list = self.template_items
    //   self.test_result_name = self.template_name
    // })
  },
  methods: {
    deleteTestsItems (index) {
      // this.test_items_list = this.test_items_list.filter((item, idx) => idx !== index)
      this.test_items_list.splice(index, 1)
    },
    addTestsItems (template_id) {
      let self = this
      var param = {
        is_monitor: true,
        test_name: '',
        test_value: '',
        lower_threshold: '',
        upper_threshold: '',
        test_unit: '',
        template_id: template_id,
        error: null
      }
      self.test_items_list.push(param)
    },
    checkValueTestsItems () {
      let self = this
      try {
        self.test_items_list.forEach(function (element, index) {
          self.test_items_list.is_error = false
          var error = {
            test_name: '',
            test_value: '',
            lower_threshold: '',
            upper_threshold: '',
            test_unit: '',
            error_threshold: ''
          }
          self.test_items_list.error = error
          if (!element.test_name) {
            error.test_name = 'Vui lòng nhập chỉ số'
            self.test_items_list.is_error = true
          }
          if (self.checkemty(element.test_value)) {
            error.test_value = 'Vui lòng nhập giá trị'
            self.test_items_list.is_error = true
          }
          if (self.checkemty(element.lower_threshold)) {
            error.lower_threshold = 'Vui lòng nhập giá trị ngưỡng dưới'
            self.test_items_list.is_error = true
          }
          if (self.checkemty(element.upper_threshold)) {
            error.upper_threshold = 'Vui lòng nhập giá trị ngưỡng trên'
            self.test_items_list.is_error = true
          }
          if (!self.checkemty(element.lower_threshold) && !self.checkemty(element.upper_threshold) && parseFloat(element.upper_threshold) < parseFloat(element.lower_threshold)) {
            error.error_threshold = 'Vui lòng nhập ngưỡng trên phải cao hơn ngưỡng thấp'
            self.test_items_list.is_error = true
          }
          if (!element.test_unit) {
            error.test_unit = 'Vui lòng nhập đơn vị'
            self.test_items_list.is_error = true
          }
          if (self.test_items_list.is_error) {
            element.error = error
          } else {
            element.error = null
          }
        })
        var result = self.test_items_list.find(item => item.error !== null && item.error !== '')
        if (result) {
          return false
        } else {
          return true
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    getError (idx) {
      let self = this
      var error = self.test_items_list.find((ele, index) => index === idx).error
      if (error) {
        return error
      } else {
        return ''
      }
    },
    checkemty (str) {
      if (typeof str === 'undefined' || str === '') {
        return true
      } else {
        return false
      }
    },
    emtyError (test_items_list) {
      try {
        if (test_items_list) {
          test_items_list.forEach(function (element, index) {
            element.error = null
          })
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    onSubmitTests () {
      let self = this
      self.is_submit = true
      if (self.test_result_name && self.checkValueTestsItems(self.test_items_list)) {
        self.is_submit = false
        var param = {
          person_diseases_id: self.$route?.params?.id,
          person_id: self.person_id,
          test_result_name: self.test_result_name,
          type: 1
        }
        var params = {
          tests: self.test_items_list,
          person_diseases_id: self.$route?.params?.id
        }
        self.$emit('onSubmitTests', param, params)
      }
    }
  }
}
</script>
<style scoped="true">
.form-control {
    color: #222222 !important;
    font-family: 'roboto';
    font-size: 16px;
    font-weight: 500;
}
</style>