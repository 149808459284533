<template>
	<div>
		<div class="modal" tabindex="-1" role="dialog" id="modal--question">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{is_edit ? tilte_edit : tilte_add}}</h5>
						<span class="btn--close"></span>
					</div>
					<div class="modal-body">
						<div class="row mt-2">
							<div class="col-sm-12">
								<div style="padding: 15px 15px;">
									<div class="row">
										<div class="col-sm-8">
											<label class="robo-16-500">Tiêu đề câu hỏi</label>
											<textarea class="form-control mt-1" v-model="tilte"></textarea>
											<span v-if="!tilte" class="text-warning">{{err}}</span>
										</div>
										<div class="col-sm-4 d-flex align-items-start justify-content-end">
											<div class="dropdown">
												<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													{{style_number == 1 ? 'Văn bản ngắn' : style_number == 2 ? 'Trắc nghiệm' : ''}}
												</button>
												<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
													<a class="dropdown-item" href="javascript:void(0)" @click="changeStatus(1)">Văn bản ngắn</a>
													<a class="dropdown-item" href="javascript:void(0)" @click="changeStatus(2)">Trắc nghiệm</a>
												</div>
											</div>
										</div>
									</div>
									<div class="input-group-sm mb-3 mt-3">
										<div class="input-group mb-3" v-if="style_number === 1">
											<div class="input-group-prepend">
												<span class="input-group-text h-100" id="inputGroup-sizing-default"></span>
											</div>
											<input type="text" class="form-control disabled" aria-label="Default" aria-describedby="inputGroup-sizing-default" placeholder="Văn bản câu trả lời ngắn">
										</div>
										<div class="input-group mb-3" v-if="style_number === 0">
											<div class="input-group-prepend">
												<span class="input-group-text h-100" id="inputGroup-sizing-default"></span>
											</div>
											<textarea class="form-control disabled" placeholder="Đoạn văn bản"></textarea>
										</div>
										<div class="input-group mb-3" v-if="style_number === 2">
											<div class="input-group-prepend">
												<div class="input-group-text h-100">
													<input class="disabled" type="checkbox" aria-label="Checkbox for following text input">
												</div>
											</div>
											<input type="text" class="form-control disabled" aria-label="Text input with checkbox">
										</div>
										<div class="input-group mb-3" v-if="style_number === 0">
											<div class="input-group-prepend">
												<div class="input-group-text h-100">
													<input class="disabled" type="radio" aria-label="Radio button for following text input">
												</div>
											</div>
											<input type="text" class="form-control" aria-label="Text input with radio button">
										</div>
                    <div class="form-group">
                      <label class="robo-16-500">Nhóm câu hỏi</label>
                      <select class="form-control" v-model="group_id" v-if="questions && questions.data">
                        <option class="robo-16-500" value="0">Chọn nhóm câu hỏi</option>
                        <option class="robo-16-500" :value="question.id" v-for="(question, index) in questions.data" :key="index">{{question.name}}</option>
                      </select>
                      <span v-if="!group_id && is_submit" class="text-warning">Không để nhóm câu hỏi trống</span>
                    </div>
									</div>
									<div class="mt-2 border-top">
										<div class="row mt-2">
											<div class="col-sm-6">
												Hoạt động
												<div class="btn-group" role="group" aria-label="Basic example">
													<button type="button" class="btn btn-sm" :class="status_question === 1 ? 'btn--blue' : 'btn--babyblue'" @click="changeQuestion(1)">Off</button>
													<button type="button" class="btn btn-sm" :class="status_question === 2 ? 'btn--blue' : 'btn--babyblue'" @click="changeQuestion(2)">On</button>
												</div>
											</div>
										</div>
										<div class="row mt-2">
											<div align="right">
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
						<button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit()">{{is_edit ? 'Chỉnh sửa' : 'Thêm'}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'questionListing',
  props: ['is_edit', 'data'],
  data () {
    return {
      tilte: '',
      describe: '',
      style_question: 'Loại trả lời',
      style_number: 1,
      status_question: 2,
      group_id: 0,
      tilte_add: 'Thêm câu hỏi',
      tilte_edit: 'Chỉnh sửa câu hỏi',
      err: '',
      questions: null,
      is_submit: null
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal--question').on('hide.bs.modal', function (e) {
        if (!self.is_edit) {
          self.tilte = ''
          self.style_question = 'Loại trả lời'
          self.style_number = 1
          self.status_question = 2
          self.err = ''
          self.group_id = 0
        }
      })
    })
    self.getGroupQuestion()
  },
  watch: {
    is_edit: {
      handler: function () {
        let self = this
        // self.tilte = self.data.content
        // self.style_question = self.data.type === 1 ? 'Đoạn văn ngắn' : self.data.type === 2 ? 'Trắc nghiệm' : ''
        // self.style_number = self.data.type
        // self.status_question = self.data.status
        if (!self.is_edit) {
          self.tilte = ''
          self.style_question = 'Loại trả lời'
          self.style_number = 1
          self.status_question = 2
          self.err = ''
          self.group_id = 0
        }
      },
      deep: true
    },
    data: {
      handler: function () {
        let self = this
        if (self.is_edit) {
          self.tilte = self.data.content
          self.style_question = self.data.type === 1 ? 'Đoạn văn ngắn' : self.data.type === 2 ? 'Trắc nghiệm' : ''
          self.style_number = self.data.type
          self.status_question = self.data.status
          self.group_id = self.data.group_id ? self.data.group_id : 0
        } else {
          self.tilte = ''
          self.style_question = 'Loại trả lời'
          self.style_number = 1
          self.status_question = 2
          self.err = ''
          self.group_id = 0
        }
      },
      deep: true
    }
  },
  methods: {
    async getGroupQuestion () {
      try {
        let self = this
        self.params = {
          limit: 10,
          search: self.search,
          sort: 'created_at',
          sort_by: 'desc'
        }
        const result = await self.$rf.getRequest('AdminRequest').getQuestionGroups(self.params)
        self.questions = result.data
        self.current_page = result.data.current_page
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    changeQuestion (type) {
      let self = this
      self.status_question = type
    },
    changeStatus (type) {
      let self = this
      self.style_number = type
      if (type === 1) {
        self.style_question = 'Trả lời ngắn'
      } else if (type === 2) {
        self.style_question = 'Trắc nghiệm'
      }
    },
    checkQuestion () {
      let self = this
      if (!self.tilte) {
        self.err = 'Không để tên tiêu đề trống'
        return false
      } if (!self.group_id) {
        return false
      } else {
        self.err = ''
        return true
      }
    },
    async onSubmit () {
      let self = this
      try {
        self.is_submit = true
        if (self.checkQuestion()) {
          var param = {
            content: self.tilte,
            status: self.status_question,
            type: self.style_number,
            group_id: self.group_id
          }
          if (self.is_edit) {
            param.id = self.data.id
          }
          self.$emit('onSubmitQuestion', param, self.is_edit)
          self.is_submit = false
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style scoped></style>