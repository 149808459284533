<template>
  <div
    class="modal fade"
    id="ModalDoEdit"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalDoEditLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div
        class="modal-content"
        v-if="edit"
      >
        <div class="modal-header">
          <h5
            class="modal-title"
            id="ModalDoEditLabel"
          >Cập nhật thông tin gói</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3 row">
            <div class="col-6"><label
              for="name"
              class="robo-18-400 mb-1 required"
            >Số tháng </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.package_months"
            >
            </div>
            <div class="col-6">
                 <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Số tháng khuyến mãi </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.combo_months"
            >
            </div>
            
          </div>
          <div class="mb-3 row">
               <div class="col-6">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Giá gốc làm tròn </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.package_price"
            >
          </div>
           <div class="col-6">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Giá sau khuyến mãi </label>
            <input
              type="number"
              class="form-control border"
              v-model="form.combo_price"
            >
          </div>
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="robo-18-400 mb-1 required"
            >Mô tả </label>
            <textarea
              class="form-control"
              v-model="form.description"
              rows="3"
            >
            </textarea>
          </div>
          <div class="mb-3 d-flex">
            <input
              class="checkbox-size"
              type="checkbox"
              id="checkbox"
              v-model="form.status"
            >
            <label
              for="checkbox"
              class="robo-16-400 ml-2 mb-1"
            >
              Active
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >Lưu</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDoEdit',
  props: ['edit'],
  data () {
    return {
      form: {
        package_months: 0,
        package_price: 0,
        combo_months: 0,
        combo_price: 0,
        status: 0,
        description: ''
      }
    }
  },
  mounted () {
  },
  watch: {
    edit (value) {
      console.log(value)
      if (!value) return
      this.form = {
        package_months: value.package_months,
        package_price: value.package_price,
        combo_months: value.combo_months,
        combo_price: value.combo_price,
        status: value.status,
        description: value.description
      }
    }
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.form.package_months) {
        alert('Nhập số tháng bán')
        return
      } else if (!self.form.combo_price) {
        alert('Nhập giá combo')
        return
      }
      if (self.form.status) {
        self.form.status = 1
      } else {
        self.form.status = 0
      }

      self.$emit('onUpdate', self.form)
      self.form = {
        package_months: 0,
        package_price: 0,
        combo_months: 0,
        combo_price: 0,
        status: 0,
        description: ''
      }
    }
  }
}
    </script>

<style scoped>
.input-file {
  height: auto;
  width: 100%;
  line-height: 24px;
  margin-top: 20px;
}
.img-fixed {
  margin-bottom: -36px;
}
.checkbox-size {
  width: 18px;
  height: 18px;
  accent-color: #03267a;
}
</style>